import React, { Component } from "react";
import { Link } from "react-router-dom";
import DarkList from "../../components/DarkList";
import axios from "axios";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import {
  Menu,
  Logo,
  MenuItem,
  Container,
  DarkContainer,
  Header
} from "./styles";
import logoBranca from "../../assets/logo-branca.png";
import { filtraStatus, formatCurrency } from "../../components/Helpers";
import { url } from "../../services/api"


class Painel extends Component {
  state = {
    boletos: [{}]
  };

  componentDidMount() {
    this.getBoletos();
  }

  toDate = date => {
    // let data = new Date(date);
    // return data.toLocaleDateString();
    return moment(date).format("DD/MM/YYYY");
  };
  async getBoletos() {
    try {
      const { cpf } = this.props.match.params;
      const boletos = await axios.get(`${url}/acha?cpf=${cpf}`);
      this.setState({
        ...this.state,
        boletos: boletos.data.data.parcels,
        student: boletos.data.data.student
      });
    } catch (err) {
      console.warn(err);
    }
  }
  render() {
    return (
      <>
        <Container>
          <Menu>
            <Logo src={logoBranca} alt="Logo da Midia Formaturas" width="100" />
            <MenuItem>Lista de boletos</MenuItem>
            <Link to={`/painel-aluno`} style={{ textDecoration: "none" }}>
              <MenuItem>Sair</MenuItem>
            </Link>
          </Menu>
          <DarkContainer>
            <Header></Header>
            <Grid container style={{ padding: 30 }}>
              <Grid item lg={12}>
                {this.state.boletos
                  .sort((a, b) => new Date(a.due_date) - new Date(b.due_date))
                  .map(boleto => {
                    return (
                      <DarkList
                        lista={[
                          {
                            key: "Vencimento",
                            value: this.toDate(boleto.due_date)
                          },
                          { key: "Valor", value: formatCurrency(boleto.price) },
                          { key: "Status", value: filtraStatus(boleto.status) }
                        ]}
                        view={true}
                        link={`${boleto.url}`}
                      />
                    );
                  })}
              </Grid>
            </Grid>
          </DarkContainer>
        </Container>
      </>
    );
  }
}

export default Painel;
