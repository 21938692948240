import React, { Component, Fragment } from "react";
import api from "../../services/api";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../components/Helpers";

// Custom Components
import Menu from "../../components/Menu";
// import Api from "../../services/api";
import List from "../../components/List";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import { CircularProgress } from "@material-ui/core";

// Other libraries
import SwipeableViews from "react-swipeable-views";
function TabContainer({ children, dir }) {
  return (
    <div dir={dir} style={{ paddingTop: 20, width: "100%" }}>
      {children}
    </div>
  );
}

const alunos = [{ name: "Giocondo Brasil" }];

class Turma extends Component {
  state = {
    turma: "",
    turmas: [],
    packages: [],
    value: 0,
    students: [],
    isLoading: true,
  };

  componentDidMount() {
    this.getTurma();
  }

  async getTurma() {
    const { id } = this.props.match.params;
    try {
      const request = await api.get(`/classrooms/${id}`);
      this.setState({ ...this.state, ...request.data, isLoading: false });
    } catch (err) {
      console.warn(err);
    }
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  mostraTurno = (turno) => {
    switch (turno) {
      case "1":
        return "Matutino";
      case "2":
        return "Vespertino";
      case "3":
        return "Noturno";
      case "4":
        return "Integral";
      default:
        break;
    }
  };

  render() {
    const { classes, theme } = this.props;
    const { id } = this.props.match.params;
    return (
      <Fragment>
        <div className={classes.root}>
          <Menu />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <AppBar position="static" style={{ backgroundColor: "#242424" }}>
              <Toolbar>
                <Typography
                  variant="h5"
                  color="inherit"
                  className={classes.grow}
                >
                  {this.state.name}
                </Typography>
                <Tooltip title="Adicionar Aluno" aria-label="Add">
                  <Link
                    to={`/turma/${id}/adicionar-aluno`}
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                      marginRight: 20,
                    }}
                  >
                    {/*<IconButton
                      className={classes.menuButton}
                      color="inherit"
                      aria-label="Menu"
                    >*/}
                    <Button variant="outlined" color="primary">
                      Adicionar Aluno
                    </Button>
                    {/*<AddBox />*/}
                    {/*</IconButton>*/}
                  </Link>
                </Tooltip>
                <Tooltip title="Adicionar Pacote" aria-label="Add">
                  <Link
                    to={`/turma/${id}/adicionar-pacote`}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <Button variant="outlined" color="primary">
                      Adicionar Pacote
                    </Button>
                    {/*<AddBox />*/}
                  </Link>
                </Tooltip>
              </Toolbar>
            </AppBar>
            <AppBar position="static" color="default">
              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab label="Alunos" />
                <Tab label="Pacotes" />
                <Tab label="Parcelas Atrasadas" />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={this.state.value}
              onChangeIndex={this.handleChangeIndex}
            >
              <TabContainer>
                {this.state.isLoading && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
                {!this.state.isLoading && (
                  <Grid container>
                    {this.state.students.map((student, index) => {
                      return (
                        <List
                          key={student.id}
                          array={this.state.students}
                          lista={[
                            { key: "Nome", value: student.name },
                            { key: "Celular", value: student.phone },
                            { key: "CPF", value: student.cpf },
                            { key: "Telefone Fixo", value: student.home_phone },
                          ]}
                          view={`/turma/${id}/aluno/${student.id}`}
                          edit={`/turma/${id}/editar-aluno/${student.id}`}
                        />
                      );
                    })}
                  </Grid>
                )}
              </TabContainer>
              <TabContainer>
                <Grid container>
                  {this.state.packages.map((pacote, index) => {
                    return (
                      <List
                        key={pacote.id}
                        array={alunos}
                        lista={[
                          { key: "Nome", value: pacote.name },
                          { key: "Preço", value: formatCurrency(pacote.price) },
                        ]}
                        edit={`/turma/${this.props.match.params.id}/editar-pacote/${pacote.id}`}
                      />
                    );
                  })}
                </Grid>
              </TabContainer>
              <TabContainer>
                <Grid container>
                  <h4>Parcelas atrasadas</h4>
                </Grid>
              </TabContainer>
            </SwipeableViews>
          </main>
        </div>
      </Fragment>
    );
  }
}

const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    minHeight: "100vh",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  grow: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto",
  },
});

export default withStyles(styles, { withTheme: true })(Turma);
