import React, { Component } from "react";
// import { Switch, Route } from "react-router-dom";
import GlobalStyle from "./styles/global";
// import Login from "./pages/Login";
// import Teste from "./routes/Teste";
// import { isAuthenticated } from "./services/auth";
import Routes from "./routes";

// class Public extends Component {
//   render() {
//     return (
//       <Switch>
//         <Route exact path="/login" component={Login} />
//         <Route component={Login} />
//       </Switch>
//     );
//   }
// }

class App extends Component {
  componentDidMount() {
    console.log("app");
  }
  render() {
    return (
      <div>
        <GlobalStyle />
        <Routes />
      </div>
    );
  }
}

export default App;
