import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: 'Roboto', sans-serif
}
html, body, #root {
    height: 100%;
}
body {
    background-color: #f7f6fb;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased;
}
`;

export default GlobalStyle;
