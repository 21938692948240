import React, { Component, Fragment } from "react";

// Custom Components
import Menu from "../../../components/Menu";
import api from "../../../services/api";
import FormPaper from "../../../components/FormPaper";
import SaveButton from "../../../components/SaveButton";

// Material-UI
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

class PacoteForm extends Component {
  state = {
    name: "",
    price: "",
    classroom_id: "",
    description: ""
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.getPacote();
    }
  }

  async getPacote() {
    try {
      const { id } = this.props.match.params;
      const request = await api.get(`/packages/${id}`);
      this.setState({ ...this.state, ...request.data });
    } catch (err) {
      console.warn(err);
    }
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  async handleSave() {
    const { id, turmaId } = this.props.match.params;
    const obj = {
      ...this.state,
      classroom_id: turmaId
    };
    try {
      if (id) {
        await api.put(`/packages/${id}`, obj);
        this.props.history.push(`/turma/${turmaId}`);
      } else {
        await api.post(`/packages`, obj);
        this.props.history.push(`/turma/${turmaId}`);
      }
    } catch (err) {
      console.warn(err);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <div className={classes.root}>
          <Menu />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <FormPaper title="Adicionar Pacote">
              <Grid container spacing={24}>
                <Grid item lg={4}>
                  <TextField
                    fullWidth
                    label="Nome"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange.bind(this)}
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    fullWidth
                    label="Preço"
                    name="price"
                    value={this.state.price}
                    onChange={this.handleChange.bind(this)}
                  />
                </Grid>

                <Grid item lg={12}>
                  <TextField
                    fullWidth
                    label="Descrição"
                    name="description"
                    value={this.state.description}
                    onChange={this.handleChange.bind(this)}
                  />
                </Grid>
                <SaveButton
                  click={this.handleSave.bind(this)}
                  route="/turmas"
                />
              </Grid>
            </FormPaper>
          </main>
        </div>
      </Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex",
    flexGrow: 1,
    minHeight: "100vh"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  grow: {
    flexGrow: 1
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  }
});

export default withStyles(styles, { withTheme: true })(PacoteForm);
