import React, { Component, Fragment } from 'react';
import moment from 'moment';
import api from '../../../../services/api';
import Boleto from '../../../../components/Boleto';
import { uniqueId } from 'lodash';

class Carne extends Component {
  state = { parcelas: [] };

  componentDidMount() {
    this.getParcelas();
  }

  formatCurrency = value => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  };

  dateParser(date) {
    const due_date = moment(date).format('DD/MM/YYYY');
    return due_date;
  }

  async getParcelas() {
    try {
      const { alunoId } = this.props.match.params;
      const request = await api.get(`/buscaparcelas?student_id=${alunoId}`);
      this.setState({ ...this.state, parcelas: request.data });
    } catch (err) {
      console.warn(err);
    }
  }
  render() {
    return (
      <Fragment>
        {this.state.parcelas.map(parcela => {
          return (
            <Boleto
              key={uniqueId()}
              valorDoc={`${this.formatCurrency(parcela.price)}`}
              vencimento={`${this.dateParser(parcela.due_date)}`}
              linhaDigitavel={`${parcela.objeto.attributes.bank_slip.digitable_line}`}
              cliente={`${parcela.student.name} - CPF ${parcela.student.cpf} - ${parcela.student.logradouro}, ${parcela.student.bairro}, ${parcela.student.cep}, ${parcela.student.numero}, ${parcela.student.localidade} - ${parcela.student.uf}`}
              transactionNumber={`${parcela.objeto.attributes.variables[11].value}`}
              codigoBarras={`${parcela.objeto.attributes.bank_slip.barcode}`}
              de
            />
          );
        })}
      </Fragment>
    );
  }
}

export default Carne;
