import React from "react";

// Carrega o axios com o interceptor
import api, { url } from "../../services/api";
import axios from "axios";
import { login } from "../../services/auth";

// Material-UI Core
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";

// Material-UI icons
import AccountCircle from "@material-ui/icons/AccountCircle";
import Lock from "@material-ui/icons/Lock";

// Styles
import "./style.css";

// Other
import swal from "@sweetalert/with-react";

class PainelAluno extends React.Component {
  state = {
    cpf: ""
  };

  salvaDados(dados) {
    localStorage.setItem("usuario", dados);
  }

  buscaParcelas = async event => {
    event.preventDefault();
    this.props.history.push(`/painel-aluno/${this.state.cpf}`);
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  render() {
    return (
      <form onSubmit={this.buscaParcelas} style={{ display: "block" }}>
        <div className="login-container">
          <div className="login__panel">
            <div className="login__logo" />
          </div>
          <div className="login__content">
            <div className="login__logo__mobile" />
            <h3 className="painel-title">Bem vindo!</h3>
            <h5 className="painel-subtitle">
              Para acessar os seus boletos, digite seu cpf!
            </h5>
            <TextField
              id="email"
              className="login__email"
              label="CPF"
              onChange={this.handleChange.bind(this)}
              type="text"
              name="cpf"
              placeholder="Insira seu cpf"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                )
              }}
            />

            <Button
              variant="contained"
              type="submit"
              color="primary"
              className="login__button"
            >
              Entrar
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

export default PainelAluno;
