import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import MenuItem from "./MenuItem";

import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";

// Icons
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HomeIcon from "@material-ui/icons/Home";
import Contacts from "@material-ui/icons/Contacts";
import Search from "@material-ui/icons/Search";
import PowerIcon from "@material-ui/icons/PowerSettingsNew";
import AttachMoney from "@material-ui/icons/AttachMoney";

// The router
//import Routes from "../config/Routes";
import { Link, withRouter } from "react-router-dom";
import { hasRole } from "../../services/auth";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "auto",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    height: "auto"
  }
});

class MiniDrawer extends React.Component {
  state = {
    open: false,
    user: {
      role: ""
    }
  };

  async componentDidMount() {
    const usuarioInfo = JSON.parse(localStorage.getItem("usuario"));
    this.setState({ ...this.state, user: { role: usuarioInfo.role.name } });
    await console.log("menu foi montado");
    // axiosClient
    //   .get(`/users/${currentUser()}`)
    //   .then(resp => this.setState({ ...this.state, user: resp.data }));
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  sair = () => {
    //logout();
    localStorage.clear();
    this.props.history.push("/login");
  };

  render() {
    const { classes, theme } = this.props;

    return (
      <Fragment>
        <AppBar
          position="absolute"
          className={classNames(
            classes.appBar,
            this.state.open && classes.appBarShift
          )}
        >
          <Toolbar
            style={{ backgroundColor: "#242424" }}
            disableGutters={!this.state.open}
          >
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                this.state.open && classes.hide
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="title" color="inherit" noWrap>
              Midia Eventos
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !this.state.open && classes.drawerPaperClose
            )
          }}
          open={this.state.open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />

          <MenuItem path="/" title="Início" icon={<HomeIcon />} />
          <MenuItem path="/turmas" title="Turmas" icon={<Contacts />} />
          <MenuItem path="/alunos" title="Alunos" icon={<Search />} />
          <MenuItem
            path="/financeiro"
            title="Financeiro"
            icon={<AttachMoney />}
          />

          <ListItem onClick={this.sair} button>
            <ListItemIcon>
              <PowerIcon />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>
        </Drawer>
      </Fragment>
    );
  }
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(MiniDrawer));
