// @format
import axios from 'axios';
import { getToken } from './auth';
export const url = 'https://midia-backoffice-api.herokuapp.com/';

/*
 * Cria um instância do axios com o nome api e define
 * uma URL padrão.
 */
const api = axios.create({
  baseURL: url
});

/*
 * Adiciona um interceptor com o header de autorização,
 * desta forma cada request enviará as credenciais
 * do usuário.
 */

api.interceptors.request.use(
  async config => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location = '/login';
    }
    return error;
  }
);

export default api;
