import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import swal from "@sweetalert/with-react";

// Custom Components
import Menu from "../../../components/Menu";
import api from "../../../services/api";
import BoletoList from "../../../components/BoletoList";
import { filtraStatus } from "../../../components/Helpers";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import AddBox from "@material-ui/icons/AddBox";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";

// Other libraries
import SwipeableViews from "react-swipeable-views";
import { CircularProgress } from "@material-ui/core";

function TabContainer({ children, dir }) {
  return (
    <div dir={dir} style={{ paddingTop: 20, width: "100%" }}>
      {children}
    </div>
  );
}

export const toDate = (date) => {
  // let data = new Date(date);
  // return data.toLocaleDateString();
  return moment(date).format("DD/MM/YYYY");
};

/*
 *  Utiliza a API nativa do Javascript de internacionalizacão
 *  para converter um determinado valor em formato de moeda.
 */
export const formatCurrency = (value) => {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });

  return formatter.format(value);
};
class Aluno extends Component {
  state = {
    turma: "",
    turmas: [],
    parcelas: [],
    value: 0,
    classroom: {},
    isLoading: true,
  };

  componentDidMount() {
    this.getStudent();
    this.getParcelas();
  }

  async getParcelas() {
    try {
      const { id } = this.props.match.params;
      const request = await api.get(`/buscaparcelas?student_id=${id}`);
      this.setState({
        ...this.state,
        parcelas: request.data,
        isLoading: false,
      });
    } catch (err) {
      console.warn(err);
    }
  }

  async getStudent() {
    try {
      const { id } = this.props.match.params;
      const request = await api.get(`/students/${id}`);
      this.setState({ ...this.state, ...request.data });
    } catch (err) {
      console.warn(err);
    }
  }

  async payParcel(index) {
    try {
      const newParcels = [...this.state.parcelas];
      const id = this.state.parcelas[index].id;
      const parcelId = this.state.parcelas[index].invoice_id;
      const obj = {
        invoice_id: parcelId,
        id,
      };
      const request = await api.put(`/pagou`, obj);
      newParcels[index].paid = true;
      this.setState({ ...this.state, parcelas: newParcels });
    } catch (error) {
      console.log(error);
    }
  }

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  mostraTurno = (turno) => {
    switch (turno) {
      case "1":
        return "Matutino";
      case "2":
        return "Vespertino";
      case "3":
        return "Noturno";
      case "4":
        return "Integral";
      default:
        break;
    }
  };

  teste(index) {
    const parcelas = [...this.state.parcelas];
    swal({
      title: "Você tem certeza?",
      text: `Tem certeza que deseja deletar a dívida de ${formatCurrency(
        parcelas[index].valor
      )} do devedor  ${parcelas[index].id} ?`,
      buttons: ["Cancelar", "Deletar"],
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log("Parceladeletada");
        const parcelId = this.state.parcelas[index].id;
        const array = [...this.state.parcelas]; // make a separate copy of the array
        api.put(`/teste`, { id: parcelId });
        api.delete(`/parcels/${parcelId}}`);
        array.splice(index, 1);
        this.setState({
          ...this.state,
          parcelas: array,
        });
        swal(`Poof! A parcela  foi deletada com sucesso!`, {
          icon: "success",
        });
      } else {
        console.log("Parcela não foi deletada");
        swal("A parcela não foi deletada!");
      }
    });
  }

  onPayParcelInLocal(index) {
    const parcelas = [...this.state.parcelas];
    swal({
      title: "Você tem certeza?",
      text: `${index} Tem certeza que deseja confirmar o pagamento local da parcela de ${formatCurrency(
        parcelas[index].valor
      )} do devedor  ${this.state.name} ?`,
      buttons: ["Cancelar", "Confirmar pagamento"],
      icon: "warning",
      dangerMode: true,
    }).then(async (willPay) => {
      if (willPay) {
        try {
          await this.payParcel(index);
          swal(` A parcela  foi marcada como paga !`, {
            icon: "success",
          });
        } catch (error) {
          swal("Houve um erro e parcela não foi paga");
        }
      } else {
        swal("A parcela não foi paga");
      }
    });
  }

  render() {
    const { classes, theme } = this.props;
    const { name, classroom } = this.state;
    const { turmaId, id } = this.props.match.params;
    return (
      <Fragment>
        <div className={classes.root}>
          <Menu />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <AppBar position="static" style={{ backgroundColor: "#242424" }}>
              <Toolbar>
                <Typography
                  variant="h5"
                  color="inherit"
                  className={classes.grow}
                >
                  {name} - {classroom.name} - {classroom.college}.
                </Typography>
                <Tooltip title="Adicionar Parcelas" aria-label="Add">
                  <Link
                    to={{
                      pathname: `/turma/${turmaId}/aluno/${id}/contrato`,
                      state: this.state,
                    }}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <IconButton
                      className={classes.menuButton}
                      color="inherit"
                      aria-label="Menu"
                    >
                      <AddBox />
                    </IconButton>
                  </Link>
                </Tooltip>
                {this.state.parcelas.length === 0 ? null : (
                  <Tooltip title="Gerar Carnê" aria-label="Add">
                    <Link
                      to={{
                        pathname: `/carne/${id}`,
                        state: this.state,
                      }}
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      Carnê
                    </Link>
                  </Tooltip>
                )}
              </Toolbar>
            </AppBar>
            <AppBar position="static" color="default">
              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab label="Parcelas" />
                <Tab label="Parcelas Atrasadas" />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={this.state.value}
              onChangeIndex={this.handleChangeIndex}
            >
              <TabContainer>
                {this.state.isLoading && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
                {!this.state.isLoading && (
                  <Grid container>
                    {this.state.parcelas
                      ? this.state.parcelas
                          .sort(
                            (a, b) =>
                              new Date(a.due_date) - new Date(b.due_date)
                          )
                          .map((parcela, index) => {
                            return (
                              <BoletoList
                                key={parcela.id}
                                onPayLocal={this.onPayParcelInLocal.bind(
                                  this,
                                  index
                                )}
                                delete={this.teste.bind(this, index)}
                                lista={[
                                  {
                                    key: "Vencimento",
                                    value: toDate(parcela.due_date),
                                  },
                                  {
                                    key: "Preço",
                                    value: formatCurrency(parcela.price),
                                  },
                                  {
                                    key: "Status",
                                    value: filtraStatus(parcela.status),
                                  },
                                  {
                                    key: "Pago no local?",
                                    value: parcela.paid ? "Sim" : "Não",
                                  },
                                ]}
                                view={parcela.url}
                              />
                            );
                          })
                      : null}
                  </Grid>
                )}
              </TabContainer>
              <TabContainer>
                <Grid container>
                  <h4>Parcelas Atrasadas</h4>
                </Grid>
              </TabContainer>
            </SwipeableViews>
          </main>
        </div>
      </Fragment>
    );
  }
}

const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    minHeight: "100vh",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  grow: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto",
  },
});

export default withStyles(styles, { withTheme: true })(Aluno);
