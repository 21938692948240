import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

// Material-UI
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import { fade } from "@material-ui/core/styles/colorManipulator";
import SearchIcon from "@material-ui/icons/Search";

// Custom Components
import Menu from "../../components/Menu";
import api from "../../services/api";
import BoletoList from "../../components/BoletoList";
import Select from "../../components/Select";
import { toDate, formatCurrency, filtraStatus } from "../../components/Helpers";

class Financeiro extends Component {
  state = {
    parcelas: [],
    data_inicio: moment().format("YYYY-MM-DD"),
    data_fim: moment().format("YYYY-MM-DD"),
    status: "",
    student_id: 1,
    classroom_id: "",
    status: "pending",
    statusses: [
      { id: "pending", name: "Pendente" },
      { id: "paid", name: "Pago" },
      { id: "canceled", name: "Cancelado" }
    ]
  };

  componentDidMount() {
    this.getParcelas();
  }

  async getParcelas() {
    try {
      const parcelas = await api.post(
        `/pesquisa-parcelas?data_inicio=${this.state.data_inicio}&data_fim=${this.state.data_fim}&status=${this.state.status}`
      );
      this.setState({ ...this.state, parcelas: parcelas.data });
    } catch (error) {
      console.warn(error);
    }
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <div className={classes.root}>
          <Menu />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <AppBar position="static" style={{ marginBottom: 20 }}>
              <Toolbar>
                <Typography
                  variant="h5"
                  color="inherit"
                  className={classes.grow}
                >
                  Financeiro
                </Typography>
                {/* <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput
                    }}
                  />
                </div> */}
              </Toolbar>
            </AppBar>

            <Grid container spacing={24}>
              <Grid item lg={3}>
                <TextField
                  name="data_inicio"
                  fullWidth
                  type="date"
                  label="Data inicio"
                  onChange={e => this.handleChange(e)}
                  value={this.state.data_inicio}
                />
              </Grid>
              <Grid item lg={3}>
                <TextField
                  name="data_fim"
                  fullWidth
                  type="date"
                  label="Data Fim"
                  onChange={e => this.handleChange(e)}
                  value={this.state.data_fim}
                />
              </Grid>
              <Grid item lg={3}>
                <Select
                  array={this.state.statusses}
                  change={this.handleChange.bind(this)}
                  label="Status"
                  default="Selecione o status"
                  name="status"
                  value={this.state.status}
                  rotaBloqueio="/aluno/:ids"
                  path="/aluno/:idd"
                />
              </Grid>
              <Grid item lg={1}>
                <Button onClick={() => this.getParcelas()}>Pesquisar</Button>
              </Grid>
              {this.state.parcelas
                .sort((a, b) => new Date(a.due_date) - new Date(b.due_date))
                .map((parcela, index) => {
                  return (
                    <BoletoList
                      key={parcela.id}
                      lista={[
                        {
                          key: "Vencimento",
                          value: toDate(parcela.due_date)
                        },
                        {
                          key: "Preço",
                          value: formatCurrency(parcela.price)
                        },
                        {
                          key: "Status",
                          value: filtraStatus(parcela.status)
                        }
                      ]}
                      view={parcela.url}
                    />
                  );
                })}
            </Grid>
          </main>
        </div>
      </Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex",
    flexGrow: 1,
    minHeight: "100vh"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  grow: {
    flexGrow: 1
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200
      }
    }
  }
});

export default withStyles(styles, { withTheme: true })(Financeiro);
