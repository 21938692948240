import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import { PrivateRoute } from "../services/auth";

import {
  Turmas,
  Login,
  TurmaForm,
  Turma,
  AlunoForm,
  Aluno,
  PacoteForm,
  Contrato,
  PainelAluno,
  Alunos,
  Financeiro,
  PainelBoletos,
  Carne
} from "./Routes";

class Routes extends Component {
  state = {
    user: {
      role: ""
    }
  };

  componentDidMount() {}

  render() {
    return (
      <Switch>
        {/* Rotas Publicas */}
        <Route exact path="/login" component={Login} />
        <Route exact path="/carne/:alunoId" component={Carne} />
        <Route exact path="/painel-aluno" component={PainelAluno} />
        <Route exact path="/painel-aluno/:cpf" component={PainelBoletos} />
        {/* Turmas */}
        <PrivateRoute exact path="/" component={Turmas} />
        <PrivateRoute exact path="/adicionar-turma" component={TurmaForm} />
        <PrivateRoute exact path="/turmas" component={Turmas} />
        <PrivateRoute exact path="/editar-turma/:id" component={TurmaForm} />
        <PrivateRoute exact path="/turma/:id" component={Turma} />
        <PrivateRoute
          exact
          path="/turma/:turmaId/aluno/:id/contrato"
          component={Contrato}
        />

        {/* Aluno */}
        <PrivateRoute
          exact
          path="/turma/:turmaId/adicionar-aluno"
          component={AlunoForm}
        />
        <PrivateRoute
          exact
          path="/turma/:turmaId/editar-aluno/:id"
          component={AlunoForm}
        />
        <PrivateRoute
          exact
          path="/turma/:turmaId/aluno/:id"
          component={Aluno}
        />
        <PrivateRoute exact path="/alunos" component={Alunos} />
        {/* Pacote */}
        <Route
          exact
          path="/turma/:turmaId/adicionar-pacote"
          component={PacoteForm}
        />
        <Route
          exact
          path="/turma/:turmaId/editar-pacote/:id"
          component={PacoteForm}
        />
        {/* Financeiro */}
        <Route exact path="/financeiro" component={Financeiro} />
      </Switch>
    );
  }
}

export default Routes;
