import React, { Component, Fragment } from "react";
import { debounce } from "lodash";
import axios from "axios";

// Custom Components
import Menu from "../../../components/Menu";
import api from "../../../services/api";
import FormPaper from "../../../components/FormPaper";
import SaveButton from "../../../components/SaveButton";
import Select from "../../../components/Select";

// Material-UI
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { isFunctionTypeParam } from "@babel/types";

class TurmaForm extends Component {
  state = {
    name: "",
    cpf: "",
    rg: "",
    email: "",
    phone: "",
    home_phone: "",
    cep: "",
    logradouro: "",
    localidade: "",
    uf: "",
    numero: "",
    complemento: "",
    bairro: "",
    classroom_id: "",
    package_id: "",
    packages: [],
    suggestions: []
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.getStudent();
    }
    this.getPackages();
  }

  async getStudent() {
    try {
      const { id } = this.props.match.params;
      const request = await api.get(`/students/${id}`);
      this.setState({ ...this.state, ...request.data });
    } catch (err) {
      console.warn(err);
    }
  }

  async getPackages() {
    try {
      const { turmaId } = this.props.match.params;
      const request = await api.get(`/pacotes?classroom_id=${turmaId}`);
      this.setState({ ...this.state, packages: request.data });
    } catch (err) {
      console.warn(err);
    }
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  async handleSave() {
    const { id, turmaId } = this.props.match.params;
    try {
      if (id) {
        const obj = {
          ...this.state
        };
        await api.put(`/students/${id}`, obj);
      } else {
        const obj = {
          ...this.state,
          classroom_id: parseInt(turmaId)
        };
        await api.post(`/students`, obj);
      }
      this.props.history.push(`/turma/${turmaId}`);
    } catch (err) {
      console.warn(err);
    }
  }

  handleCep = e => {
    this.setState({ ...this.state, cep: e.target.value });
    let cep = e.target.value;

    this.pegaCep(cep);
  };

  pegaCep = debounce(e => {
    console.log(
      "Debounced Event:",
      axios.get(`https://viacep.com.br/ws/${e}/json/`).then(resp =>
        this.setState({
          ...this.state,
          ...resp.data
        })
      )
    );
  }, 1000);

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <div className={classes.root}>
          <Menu />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <AppBar position="static" style={{ marginBottom: 20 }}>
              <Toolbar>
                <Typography
                  variant="h5"
                  color="inherit"
                  className={classes.grow}
                >
                  Adicionar Aluno
                </Typography>
              </Toolbar>
            </AppBar>
            <FormPaper title="Informações">
              <Grid container spacing={24}>
                {this.state.packages ? (
                  <Grid item lg={3}>
                    <Select
                      array={this.state.packages}
                      change={this.handleChange.bind(this)}
                      label="Pacote"
                      default="Escolha um pacote"
                      name="package_id"
                      value={this.state.package_id}
                      rotaBloqueio="/aluno/:ids"
                      path="/aluno/:idd"
                    />
                  </Grid>
                ) : null}
                <Grid item lg={3}>
                  <TextField
                    fullWidth
                    label="Nome"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange.bind(this)}
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    fullWidth
                    label="CPF"
                    name="cpf"
                    value={this.state.cpf}
                    onChange={this.handleChange.bind(this)}
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    fullWidth
                    label="RG"
                    name="rg"
                    value={this.state.rg}
                    onChange={this.handleChange.bind(this)}
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange.bind(this)}
                  />
                </Grid>
              </Grid>
            </FormPaper>
            <FormPaper title="Contato">
              <Grid container spacing={24}>
                <Grid item lg={2}>
                  <TextField
                    fullWidth
                    label="Celular"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.handleChange.bind(this)}
                  />
                </Grid>
                <Grid item lg={2}>
                  <TextField
                    fullWidth
                    label="Telefone Fixo"
                    name="home_phone"
                    value={this.state.home_phone}
                    onChange={this.handleChange.bind(this)}
                  />
                </Grid>
              </Grid>
            </FormPaper>
            <FormPaper title="Endereço">
              <Grid container spacing={24}>
                <Grid item lg={2}>
                  <TextField
                    fullWidth
                    label="CEP"
                    name="cep"
                    value={this.state.cep}
                    onChange={this.handleCep.bind(this)}
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    fullWidth
                    label="Rua"
                    name="logradouro"
                    value={this.state.logradouro}
                    onChange={this.handleChange.bind(this)}
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    fullWidth
                    label="Bairro"
                    name="bairro"
                    value={this.state.bairro}
                    onChange={this.handleChange.bind(this)}
                  />
                </Grid>
                <Grid item lg={1}>
                  <TextField
                    fullWidth
                    label="Número"
                    name="numero"
                    value={this.state.numero}
                    onChange={this.handleChange.bind(this)}
                  />
                </Grid>
                <Grid item lg={2}>
                  <TextField
                    fullWidth
                    label="Cidade"
                    name="localidade"
                    value={this.state.localidade}
                    onChange={this.handleChange.bind(this)}
                  />
                </Grid>
                <Grid item lg={1}>
                  <TextField
                    fullWidth
                    label="UF"
                    name="uf"
                    value={this.state.uf}
                    onChange={this.handleChange.bind(this)}
                  />
                </Grid>
              </Grid>
            </FormPaper>
            <Paper style={{ padding: 20, marginTop: 20 }}>
              <SaveButton
                click={this.handleSave.bind(this)}
                route={`/turma/${this.props.match.params.turmaId}`}
              />
            </Paper>
          </main>
        </div>
      </Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex",
    flexGrow: 1,
    minHeight: "100vh"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  grow: {
    flexGrow: 1
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  }
});

export default withStyles(styles, { withTheme: true })(TurmaForm);
