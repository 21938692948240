import styled from "styled-components";
import painelBg from "../../assets/painel-bg.jpg";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Menu = styled.div`
  width: 20%;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.img`
  width: 100px;
  margin-top: 40px;
`;

export const MenuItem = styled.h4`
  font-size: 1em;
  color: #fff;
  text-align: left;
  margin-top: 20px;
`;

export const DarkContainer = styled.div`
  width: 80%;
  height: 100vh;
  background: #fff;
`;

export const Header = styled.div`
  width: 100%;
  height: 200px;
  background: url(${painelBg});
  background-position: top;
  opacity: 0.3;
  background-repeat: no-repeat;
`;
