import React from "react";

// React Router
import { Link } from "react-router-dom";

//  Material UI - Components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

// Utils
import If from "../../components/If";

export default ({ path, title, icon, show = true }) => (
  <If show={show}>
    <Link style={{ textDecoration: "none" }} to={path}>
      <List>
        <ListItem button>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={title} />
        </ListItem>
      </List>
    </Link>
  </If>
);
