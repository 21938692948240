import styled from 'styled-components';

export const Bar = styled.div`
    width: 100%;
    height: 40px;
    background-color: #3f50b4;
    padding: 0;
    margin-top: 20px;
`

export const TitleBar = styled.h3`
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1.1em;
    padding: 10px 16px 10px 16px;
`