/*
 *
 *
 *
 */

export const toDate = date => {
  let data = new Date(date);
  return data.toLocaleDateString();
};

/*
 *  Utiliza a API nativa do Javascript de internacionalizacão
 *  para converter um determinado valor em formato de moeda.
 */
export const formatCurrency = value => {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2
  });

  return formatter.format(value);
};

export const filtraStatus = status => {
  const obj = {
    pending: "Pendente",
    paid: "Pago",
    canceled: "Cancelada",
    expired: "Expirada"
  };
  return obj[status];
};
