import React from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { uniqueId } from "lodash";
import Fab from "@material-ui/core/Fab";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import Delete from "@material-ui/icons/Delete";
import { ListContainer, Info, ButtonContainer } from "./styles";

const List = props => {
  return (
    <ListContainer>
      <Info>
        {props.lista.map(item => {
          return (
            <div key={uniqueId()}>
              <Typography variant="caption" style={{ color: "#242424" }}>
                {item.key}
              </Typography>
              <Typography variant="body1" style={{ color: "#242424" }}>
                {item.value}
              </Typography>
            </div>
          );
        })}

        <ButtonContainer>
          {props.view ? (
            <a
              href={props.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Fab
                color="primary"
                style={{ fontSize: 5, width: 37, height: 20 }}
              >
                <Visibility size="small" />
              </Fab>
            </a>
          ) : null}
        </ButtonContainer>
      </Info>
    </ListContainer>
  );
};

export default List;
