import React from "react";

// Carrega o axios com o interceptor
import api, { url } from "../../services/api";
import axios from "axios";
import { login } from "../../services/auth";

// Material-UI Core
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";

// Material-UI icons
import AccountCircle from "@material-ui/icons/AccountCircle";
import Lock from "@material-ui/icons/Lock";

// Styles
import "./login.css";

// Other
import swal from "@sweetalert/with-react";

class Login extends React.Component {
  state = {
    email: "",
    password: ""
  };

  salvaDados(dados) {
    localStorage.setItem("usuario", dados);
  }

  // Envia o objeto auth com as credenciais do login
  efetuaLogin = async event => {
    event.preventDefault();
    const request = {
      auth: { email: this.state.email, password: this.state.password }
    };
    try {
      const auth = await axios.post(`${url}/user_token`, request);
      login(auth.data.token);
      const user = await api.get("/profile");
      this.salvaDados(JSON.stringify(user.data));
      await this.props.history.push("/turmas");
    } catch (err) {
      swal(
        "Email ou Senha não encontrados!",
        ` Provavelmente você digitou alguma coisa errado!`,
        "error",
        {
          button: "Tentar de novo!"
        }
      );
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  render() {
    return (
      <form onSubmit={this.efetuaLogin} style={{ display: "block" }}>
        <div className="login-container">
          <div className="login__panel">
            <div className="login__logo" />
          </div>
          <div className="login__content">
            <div className="login__logo__mobile" />
            <TextField
              id="email"
              className="login__email"
              label="Email"
              onChange={this.handleChange.bind(this)}
              type="email"
              name="email"
              placeholder="Seu email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              id="input-with-icon-textfield"
              type="password"
              label="Senha"
              placeholder="Digite sua senha"
              name="password"
              onChange={this.handleChange.bind(this)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                )
              }}
            />
            <Button
              variant="contained"
              type="submit"
              color="primary"
              className="login__button"
            >
              Entrar
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

export default Login;
