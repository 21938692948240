import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

export const SaveButton = props => (
  <Grid item lg={12}>
    <Link to={props.route} style={{ textDecoration: "none", marginRight: 10 }}>
      <Button variant="contained" color="secondary">
        Cancelar
      </Button>
    </Link>
    <Button onClick={props.click} variant="contained" color="primary">
      Salvar
    </Button>
  </Grid>
);

export default SaveButton;
