import React, { Component, Fragment } from "react";
import iugu_pdf_logo from "./iugu_pdf_logo.png"
import bradesco_logo from "./bradesco_logo.png"
import "./teste.css"
import ReactToPrint from 'react-to-print';




class Example extends React.Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => <a href="#"></a>}
          content={() => this.componentRef}
        />
        <Teste ref={el => (this.componentRef = el)} valorDoc={this.props.valorDoc} vencimento={this.props.vencimento} transactionNumber={this.props.transactionNumber} cliente={this.props.cliente} linhaDigitavel={this.props.linhaDigitavel} codigoBarras={this.props.codigoBarras} />
      </div>
    );
  }
}

class Teste extends Component {
  render() {
    return (
      <div >
        <div className="invoice">

<div className="wrapper">
<div className="container">
    <div className="row">
        <div className="bank_slip_form">
<div className="default_bank_slip_form">
<div className="screen hidden-print">
<div className="row">
<div className="col-sm-12">
<div className="form_header">
<div className="hidden-xs">
<img alt="Iugu_pdf_logo" src={iugu_pdf_logo} width="250" />
  
</div>
</div>
</div>
</div>
<div className="bank_slip_info hidden-xs">
<div className="row">
<div className="col-sm-12">
<div className="rel" style={{position: "relative"}}>
<div className="bank_slip_icon">
<img  alt="Bradesco_logo" src={bradesco_logo}  style={{maxWidth: 40, maxHeight: 40}} />
</div>
<div className="bank_slip_label icon_space">
<div className="bank-info">
<h5>BANCO BRADESCO S.A.</h5>
<span>237</span>
</div>
<h5>{this.props.linhaDigitavel}</h5>
</div>
</div>
</div>
</div>
<div className="row">
<div className="col-sm-10 left_bank_info">
<div className="row transferor-line">
<div className="col-sm-12">
<div className="bank_slip_label">
<span style={{marginTop: 8}} >LOCAL DE PAGAMENTO</span>
<h5>Pagável em qualquer banco ou lotérica.</h5>
</div>
</div>
</div>
<div className="row transferor-line">
<div className="col-sm-6">
<div className="bank_slip_label">
<span>Cedente</span>
<h5>MIDIA EVENTOS FORMATURAS</h5>
<div className="cpf_or_cnpj">
<span>CNPJ: 26.774.847/0001-17</span>
</div>
</div>
</div>
<div className="col-sm-6">
<div className="bank_slip_label">
<span>INTERMEDIADO POR</span>
<h5 className="iugu-name">Iugu Serviços na Internet SA</h5>
<div className="cpf_or_cnpj">
<span>CNPJ: 15.111.975/0001-64</span>
</div>
</div>
</div>
</div>
<div className="row transferor-line">
<div className="col-sm-12">
<div className="bank_slip_label instruction_div">
<span>INSTRUÇÕES</span>
<h5>Não receber após 30 dias do vencimento.</h5>
</div>
</div>
</div>
<div className="row">
<div className="col-sm-12">
<div className="bank_slip_label">
<span>Cliente</span>
<h5>{this.props.cliente}</h5>
</div>
</div>
</div>
</div>
<div className="col-sm-2 right_bank_slip_info">
<div className="row">
<div className="col-sm-12">
<div className="bank_slip_label reverse">
<span>Nosso Número</span>
<h5>{this.props.transactionNumber}</h5>
</div>
</div>
</div>
<div className="row">
<div className="col-sm-12">
<div className="bank_slip_label reverse">
<span>Vencimento </span>
<h5>{this.props.vencimento}</h5>
</div>
</div>
</div>
<div className="row">
<div className="col-sm-12">
<div className="bank_slip_label reverse">
<span>Valor do Doc.</span>
<h5>{this.props.valorDoc} </h5>
</div>
</div>
</div>
<div className="row">
<div className="col-sm-12">
<div className="bank_slip_label reverse">
<span>Multa/Juros</span>
</div>
</div>
</div>
<div className="row">
<div className="col-sm-12">
<div className="bank_slip_label reverse last_label">
<span>Valor a Pagar</span>
</div>
</div>
</div>
</div>
</div>
</div>
<div className="barcode_info">
<div className="row">
<div className="col-sm-9" style={{marginTop: 0}} >
<h6>Use este código de barras para pagamentos no bankline</h6>
<h5>{this.props.linhaDigitavel}</h5>
</div>
<div className="col-sm-3"></div>
</div>
</div>
<div className="row">
<div className="col-sm-9 hidden-xs">
    <img src={`${this.props.codigoBarras}`} alt=""/>
</div>
<div className="col-sm-3">
<div className="relative">
<div className="visible-xs" style={{height: 15}} ></div>
</div>
</div>
</div>
<div className="row"  style={{marginTop: 15}}>
<div className="col-sm-3 col-sm-offset-9">
<div className="relative">
<div className="visible-xs"  style={{height: 15}}></div>
</div>
</div>
</div>
</div>
<div className="printer">
<div className="page-break" style={{}} ></div>
<div className="row print-bs-header">
<div className="col-xs-6">
{/* <h5>Boleto Bancário</h5> */}
</div>
<div className="col-xs-6 reverse">
{/* <h5 className="mechanical-aut">AUTENTICAÇÃO MECÂNICA</h5>  */}
</div>
</div>
<div className="row cut-this-line">
<div className="col-xs-12">
<span className="gray">CORTE NESTA LINHA PONTILHADA</span>
</div>
<div className="col-xs-12">
</div>
</div>
<div className="row">
<div className="bank-slip-info" style={{marginBottom: 0, paddingBottom: 0}}>
<img alt="Bradesco_logo" className="bank-slip-icon" src={bradesco_logo}  style={{maxWidth: 40, maxHeight: 40}} />
<img alt="Iugu_pdf_logo" className="iugu-icon" src={iugu_pdf_logo} />
<div className="col-xs-12">
<div className="bank-slip-label">
<div className="line bank-info">
<h5>BANCO BRADESCO S.A.</h5>
<span className="gray">237</span>
</div>
<div>
    <h5>{this.props.linhaDigitavel}</h5>
</div>
</div>
</div>
<div className="col-xs-10 no-padding-left">
<div className="col-xs-12">
<div className="bank-slip-label no-space-right">
<span className="gray">LOCAL DE PAGAMENTO</span>
<h5>Pagável em qualquer banco ou lotérica.</h5>
</div>
</div>
<div className="col-xs-6">
<div className="bank-slip-label no-space-right">
<span>Cedente</span>
<h5>MIDIA EVENTOS FORMATURAS</h5>
<div className="cpf_or_cnpj">
<span>CNPJ: 26.774.847/0001-17</span>
</div>
</div>
</div>
<div className="col-xs-6 no-padding-left">
<div className="bank-slip-label no-space-left">
<span>INTERMEDIADO POR</span>
<h5 className="iugu-name">Iugu Serviços na Internet SA</h5>
<div className="cpf_or_cnpj">
<span>15.111.975/0001-64</span>
</div>
</div>
</div>
<div className="col-xs-12">
<div className="bank-slip-label no-space-right instruction-div">
<span className="gray">INSTRUÇÕES</span>
<h5>Não receber após o dia 03/02/2020.</h5>
</div>
</div>
<div className="col-xs-12">
<div className="bank-slip-label">
<span>Cliente</span>
<h5>{this.props.cliente}</h5>
</div>
</div>
</div>
<div className="col-xs-2 right-bank-slip-info">
<div className="row">
<div className="col-xs-12 no-padding-left">
<div className="bank-slip-label reverse no-space-left">
<span>Nosso Número</span>
<h5>{this.props.transactionNumber}</h5>
</div>
</div>
</div>
<div className="row">
<div className="col-xs-12 no-padding-left">
<div className="bank-slip-label reverse no-space-left">
<span>Vencimento </span>
<h5>{this.props.vencimento}</h5>
</div>
</div>
</div>
<div className="row">
<div className="col-xs-12 no-padding-left">
<div className="bank-slip-label reverse no-space-left">
<span>Valor do Doc.</span>
<h5>R$ {this.props.valorDoc}</h5>
</div>
</div>
</div>
<div className="row">
<div className="col-xs-12 no-padding-left">
<div className="bank-slip-label reverse no-space-left">
<span>Multa/Juros</span>
</div>
</div>
</div>
<div className="row">
<div className="col-xs-12 no-padding-left">
<div className="bank-slip-label reverse no-space-left last-label">
<span>Valor a Pagar</span>
</div>
</div>
</div>
</div>
<div className="bot-div" style={{position: 'relative', top: -10}}>
<div className="col-xs-10 no-padding-left">
<div className="barcode_info" style={{marginTop: 0, paddingTop: 0}}>
<div className="digitable-line"  style={{textAlign: "center"}}>
<div className="row">
<div className="col-xs-9"  style={{marginTop: 0}}>
<h6>Linha Digitável</h6>
<h5>{this.props.codigoBarras}</h5>
</div>
<div className="col-xs-3"></div>
</div>
</div>
<div className="row" style={{borderBottom: '1px solid #000'}}>
<div className="col-xs-12">
<div className="barcode-html"><img alt="Bc388702-ff25-4244-9aed-26d69f06fb97-d976" src={this.props.codigoBarras} /></div>
</div>
</div>
</div>
</div>
{/* <div className="bank-slip-aut-label"> */}
{/* <span>AUTENTICAÇÃO MECÂNICA</span>  */}
 {/* </div> */}
</div>
</div>
</div>
</div>
</div>
</div>
    </div>
</div>
</div>

</div>
      </div>
      
      
    );
  }
}

export default Example;
