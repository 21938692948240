import React from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Other Libraries
import swal from '@sweetalert/with-react';
import moment from 'moment';
import { uniqueId } from 'lodash';

// Custom Components
import api from '../../../../services/api';
import Select from '../../../../components/Select';

// const diasVencimento = [
//   { name: 1, id: 1 },
//   { name: 2, id: 2 },
//   { name: 3, id: 3 },
//   { name: 4, id: 5 },
//   { name: 6, id: 6 },
//   { name: 7, id: 7 },
//   { name: 8, id: 8 },
//   { name: 9, id: 9 },
//   { name: 10, id: 10 },
//   { name: 9, id: 11 },
//   { name: 9, id: 9 },
//   { name: 9, id: 9 },
//   { name: 9, id: 9 },
//   { name: 9, id: 9 },
//   { name: 9, id: 9 },
//   { name: 25, id: 25 },
//   { name: 30, id: 30 }
// ];

const styles = {
  appBar: {
    position: 'relative'
  },
  flex: {
    flex: 1
  }
};

function Transition(props) {
  return <Slide direction='up' {...props} />;
}

class FullScreenDialog extends React.Component {
  state = {
    open: false,
    pagamento: '',
    year: '',
    month: '',
    parcelas: [
      // {
      //   price: 0,
      //   due_date: moment().format("YYYY-MM-DD"),
      //   order: 0,
      //   tipo: "E",
      //   status: "pendente",
      //   user_id: "",
      //   user: {},
      //   classroom_id: "",
      //   classroom: {},
      //   package_id: "",
      //   package: {
      //     id: ""
      //   }
      // }
    ],
    data_entrada: moment(),
    qtd: [],
    price: parseFloat(this.props.history.location.state.package.price),
    qtdParcela: 1,
    day: '',
    valor: 1800,
    name: '',
    dia_vencimento: '',
    entrada: ''
  };

  componentWillMount() {
    this.generateQtd();

    // this.setState({
    //   classroom_id: this.props.classroom,
    //   package_id: this.props.id
    // });
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      ...this.props.history.location.state
    });

    this.getContratos();
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  handleEntrada = event => {
    let parcela = this.state.parcelas[0];
    parcela.price = parseFloat(event.target.value);
    parcela.student_id = this.state.id;
    parcela.classroom_id = this.state.classroom_id;
    parcela.package_id = this.state.package_id;
    this.forceUpdate();
  };
  handleDataEntrada = event => {
    let parcela = this.state.parcelas[0];
    parcela.due_date = event.target.value;
    parcela.student_id = this.state.id;
    parcela.classroom_id = this.state.classroom_id;
    parcela.package_id = this.state.package_id;
    this.forceUpdate();
  };
  // async enviaParcelas(id, classroom) {
  //   try {
  //     const usuario = JSON.parse(localStorage.getItem("usuario"));
  //     const obj = {
  //       user_id: usuario.id,
  //       classroom_id: classroom,
  //       package_id: id,
  //       price: this.props.price,
  //       parcels_attributes: { ...this.state.parcelas }
  //     };
  //     // alert(JSON.stringify(obj));
  //     await api.post("/contracts", obj);
  //   } catch (err) {
  //     console.warn(err);
  //   }
  // }

  async limpaParcelas() {
    await this.setState({
      pagamento: '',
      data_entrada: moment(),
      parcelas: [
        // {
        //   price: 0,
        //   due_date: moment().format('YYYY-MM-DD'),
        //   order: 0,
        //   tipo: 'E',
        //   status: 'pendente',
        //   user_id: '',
        //   user: {},
        //   classroom_id: '',
        //   classroom: {},
        //   package_id: '',
        //   package: {
        //     id: ''
        //   }
        // }
      ],
      qtd: [],
      price: parseFloat(this.props.history.location.state.package.price),
      qtdParcela: 1,
      valor: 1800,
      name: '',
      dia_vencimento: '',
      entrada: '',
      month: '',
      year: ''
    });
  }

  async getContratos() {
    try {
      const contratos = api.get(`/meuscontratos`);
      this.setState({ ...this.state, contratos: contratos.data });
    } catch (err) {
      console.warn(err);
    }
  }

 async salvaContrato() {
  try {
    // 1. Create the contract object from the component's state
    const contrato = {
      student_id: this.state.id,
      classroom_id: this.state.classroom_id,
      package_id: this.state.package_id,
    };

    // 2. Post the contract to the API
    const contractResponse = await api.post(`/contracts`, contrato);

    // 3. Validate the contract creation response
    if (!contractResponse.data || !contractResponse.data.id) {
      throw new Error('Falha ao criar o contrato.');
    }

    const contractId = contractResponse.data.id;

    // 4. Iterate over each parcela sequentially using a for...of loop
    for (const parcela of this.state.parcelas) {
      try {
        // 4.1. Create the parcela object with the associated contract ID
        const parcelaObj = {
          ...parcela,
          contract_id: contractId,
        };

        // 4.2. Post the parcela to the API
        await api.post('/parcels', parcelaObj);
      } catch (parcelaError) {
        // 4.3. Handle errors for individual parcela creation
        console.warn(`Erro ao criar a parcela: ${parcelaError}`);
        // Optionally, you can choose to:
        // - Continue with the next parcela
        // - Break the loop and handle the error
        // For this example, we'll continue with the next parcela
      }
    }

    // 5. Show success message to the user
    await swal(
      'Pacote Contratado!',
      'Suas parcelas foram geradas, agora é só pagar a entrada!',
      'success'
    );

    // 6. Update the component's state (no need to await setState)
    this.setState({ open: false });

    // 7. Redirect the user to the specified route
    this.props.history.push(
      `/turma/${this.state.classroom_id}/aluno/${this.state.id}`
    );
  } catch (error) {
    // 8. Handle any errors that occurred during contract creation or parcel processing
    console.warn(`Erro ao salvar o contrato: ${error}`);

    // Optionally, display an error message to the user
    await swal(
      'Erro!',
      'Houve um problema ao contratar o pacote. Por favor, tente novamente.',
      'error'
    );
  }
} 

  async salva() {
    await swal(
      'Pacote Contratado!',
      'Suas parcelas foram geradas, agora é só pagar a entrada!',
      'success'
    );
    await this.setState({ open: false });
  }

  async enviaParcelas(parcela) {
    try {
      await api.post('/parcels', parcela);
    } catch (err) {
      console.warn(err);
    }
  }

  generateQtd() {
    for (var i = 0; i < this.state.qtd; i++) {
      this.state.qtd.push({
        id: i + 1,
        name: i + 1
      });
    }

    this.forceUpdate();
  }

  handleAdd(valorContrato) {
    let counter = this.state.qtdParcela;
    const dataInicio = `${this.state.year}-0${this.state.month}-${this.state.dia_vencimento}`;
    for (var i = 0; i < counter; i++) {
      const usuario = JSON.parse(localStorage.getItem('usuario'));
      const valor = parseFloat(valorContrato);
      const valorFinal = valor / this.state.qtdParcela;
      if (i === 0) {
        this.state.parcelas.push({
          order: i + 1 + 1,
          tipo: i + 1 + 1 === 1 ? 'E' : 'P',
          price: valorFinal,
          forma_pagamento: 1,
          status: 'pending',
          student_id: this.state.id,
          classroom_id: this.state.classroom_id,
          student: {
            id: this.state.id
          },
          classroom: {
            id: this.state.classroom_id
          },
          package_id: this.state.package_id,
          // url: "",
          // id_invoice: "",
          due_date: moment(dataInicio)
            .set('date', this.state.dia_vencimento)
            // .set('month', this.state.month - 2)
            // .set('year', this.state.year)
            // .set('day', this.state.dia_vencimento)
            .format('YYYY-MM-DD')
        });
      } else {
        this.state.parcelas.push({
          order: i + 1 + 1,
          tipo: i + 1 + 1 === 1 ? 'E' : 'P',
          price: valorFinal,
          forma_pagamento: 1,
          status: 'pending',
          student_id: this.state.id,
          classroom_id: this.state.classroom_id,
          student: {
            id: this.state.id
          },
          classroom: {
            id: this.state.classroom_id
          },
          package_id: this.state.package_id,
          // url: "",
          // id_invoice: "",
          due_date: moment(dataInicio)
            .set('date', this.state.dia_vencimento)
            .set('month', this.state.month - 2)
            // .set('year', this.state.year)
            // .set('day', this.state.dia_vencimento)
            .add(i + 1, 'month')
            .format('YYYY-MM-DD')
        });
      }
      console.log(dataInicio);
      this.forceUpdate();
      this.setState({ status: 'aguardando_aceite' });
    }
  }

  formatCurrency = value => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color='inherit'
              onClick={this.handleClose}
              aria-label='Close'
            >
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' color='inherit' className={classes.flex}>
              {this.state.name} R$ {this.state.price}
            </Typography>
            <Button color='inherit' onClick={this.salvaContrato.bind(this)}>
              Gerar Boletos
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container spacing={24} style={{ padding: 10 }}>
          <Grid item lg={6}>
            <Grid container spacing={24}>
              {/*<Grid item lg={6}>
                  <Select
                    label="Forma de Pagamento"
                    name="pagamento"
                    default="Forma de pagamento"
                    array={formaPagamento}
                    change={this.handleChange}
                    value={this.state.pagamento}
                    helper="Forma de Pagamento"
                    path="oi"
                    rotaBloqueio="ai"
                  />
                </Grid>*/}
              <Grid item lg={4}>
                <TextField
                  fullWidth
                  label='Preco'
                  name='price'
                  value={this.state.price}
                  onChange={this.handleChange.bind(this)}
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  fullWidth
                  label='Quantidade de Parcelas'
                  name='qtdParcela'
                  value={this.state.qtdParcela}
                  onChange={this.handleChange.bind(this)}
                />
              </Grid>
              {/* <Grid item lg={4}>
                <TextField
                  fullWidth
                  label="Entrada"
                  name="entrada"
                  value={this.state.parcelas[0].price}
                  onChange={this.handleEntrada.bind(this)}
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  fullWidth
                  label="Data Entrada"
                  name="data_entrada"
                  value={this.state.parcelas[0].due_date}
                  onChange={this.handleDataEntrada.bind(this)}
                />
              </Grid> */}
              <Grid item lg={4}>
                <TextField
                  fullWidth
                  label='Mês'
                  name='month'
                  helperText='Ex: 01'
                  value={this.state.month}
                  onChange={this.handleChange.bind(this)}
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  fullWidth
                  label='Ano'
                  helperText='Ex: 2019'
                  name='year'
                  value={this.state.year}
                  onChange={this.handleChange.bind(this)}
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  fullWidth
                  label='Dia'
                  name='dia_vencimento'
                  helperText='Ex: 01'
                  value={this.state.dia_vencimento}
                  onChange={this.handleChange.bind(this)}
                />
              </Grid>
              <Grid item lg={12}>
                <Button
                  onClick={this.handleAdd.bind(this, this.state.price)}
                  variant='contained'
                  color='primary'
                >
                  Gerar Parcelas
                </Button>
                <Button
                  onClick={this.limpaParcelas.bind(this)}
                  variant='contained'
                  color='primary'
                  style={{ marginLeft: 10 }}
                >
                  Limpar campos
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Parcela</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell align='left'>Vencimento</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.parcelas.length === 0
                  ? null
                  : this.state.parcelas.map((parcela, index) => {
                      return (
                        <TableRow key={uniqueId()}>
                          <TableCell align='left'>{index + 1}</TableCell>
                          <TableCell component='th' scope='row'>
                            {this.formatCurrency(parcela.price)}
                          </TableCell>
                          <TableCell align='left'>
                            {moment(parcela.due_date).format('DD/MM/YYYY')}
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </div>
    );
  }
}

FullScreenDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FullScreenDialog);
