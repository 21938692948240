import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";

export const Selects = props => {
  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel htmlFor="component-simple">{props.default}</InputLabel>
      <Select
        displayEmpty
        fullWidth
        disabled={props.path === props.rotaBloqueio ? true : false}
        name={props.name}
        value={props.value}
        onChange={props.change}
      >
        {props.array.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.name || option.title || option.nome}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{props.helper}</FormHelperText>
    </FormControl>
  );
};

export default Selects;
