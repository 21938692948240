import React from "react";
import { Route, Redirect } from "react-router-dom";
/*
 * Funções de autenticação dos usuários
 */

// Define o nome do token que serå inserido no LocalStorage
export const TOKEN_KEY = "jwt";

// Verifica se o usuário está autenticado
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

// Pega o valor do token do LocalStorage
export const getToken = () => localStorage.getItem(TOKEN_KEY);

// Realiza o login. Salva o token do usuário no LocalStorage
export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};

// Remove o token do usuário do LocalStorage causando seu logout
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

// Verifica se o usuario tem os privilegios para acessar a rota
export const hasRole = (user, roles) =>
  roles.some(role => user.role.includes(role));

/*
 * Componente que protege as rotas. Caso o usuårio não possua
 * um token de acesso ele não poderá acessar a rota e
 * será redirecionado para a tela de login.
 */

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);
