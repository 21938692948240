import styled from "styled-components";

export const ListContainer = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #dadada;
  margin-bottom: 10px;
  padding: 15px;
`;

export const ButtonContainer = styled.div`
  text-align: right;
`

export const Size = styled.h3`
  color: #fff;
  position: absolute;
  left: 30px;
  top: 14px;
  font-size: 1.3em;
`;

export const Info = styled.div`
  display: flex;
  width: 100%;
  margin-top: -8px;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;
  div {
    width: 40%;
  }
`;

export const Avatar = styled.img`
  position: absolute;
  top: 5px;
  left: 70px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #dadada;
  object-fit: cover;
`;
