import React, { Fragment, Component } from "react";

// Services
import api from "../../services/api";

// Material-UI
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

// Custom Components
import Bar from "../../components/Bar";
import List from "../../components/List";
import Menu from "../../components/Menu";

// Other
import swal from "@sweetalert/with-react";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { debounce } from "lodash";

class Alunos extends Component {
  state = {
    entries: [],
    current: 1,
    search: "",
    current_page: 1
  };

  componentDidMount() {
    this.getStudents(this.state.current);
  }

  handleChange = debounce(text => {
    this.setState({ ...this.state, search: text }, async () => {
      try {
        const students = await api.get(
          `/students?page=${this.state.current_page}&search=${text}`
        );
        this.setState({
          ...this.state,
          current: students.data.current_page,
          per_page: students.data.per_page,
          total: students.data.total_entries,
          entries: students.data.entries
        });
      } catch (err) {
        console.warn(err);
      }
    });
  }, 500);

  async getStudents(current_page) {
    try {
      const students = await api.get(
        `/students?page=${current_page}&search=${this.state.search}`
      );
      this.setState({
        ...this.state,
        current: students.data.current_page,
        per_page: students.data.per_page,
        total: students.data.total_entries,
        entries: students.data.entries
      });
    } catch (err) {
      console.warn(err);
    }
  }

  muda = page => {
    this.setState({ ...this.state, current: page });
    this.getStudents(page);
    window.scrollTo(0, 0);
  };

  handleDelete(index) {
    swal({
      title: "Você tem certeza?",
      text: `Tem certeza que deseja remover a usuário "name[index].name
			}" ?`,
      buttons: ["Cancelar", "Deletar"],
      icon: "warning",
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        const students = [...this.state.students];
        api.delete(`/users/name[index].id}`);
        students.splice(index, 1);
        this.setState({ ...this.state, students });
        swal(`Poof! A usuário foi removida com sucesso!`, {
          icon: "success"
        });
      } else {
        swal("A usuário não foi removida !");
      }
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <div className={classes.root}>
          <Menu />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Bar title="Alunos" link="adicionar-aluno">
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  name="search"
                  onChange={e => this.handleChange(e.target.value)}
                  placeholder="Pesquisar"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                />
              </div>
            </Bar>
            <Grid container style={{ marginTop: 20 }}>
              {this.state.entries.map((aluno, index) => {
                return (
                  <List
                    key={aluno.id}
                    lista={[
                      { key: "Nome", value: aluno.name },
                      { key: "CPF", value: aluno.cpf },
                      { key: "Celular", value: aluno.phone }
                    ]}
                    edit={`/turma/${aluno.classroom_id}/editar-aluno/${aluno.id}`}
                    view={`/turma/${aluno.classroom_id}/aluno/${aluno.id}`}
                    delete={this.handleDelete.bind(this, index)}
                  />
                );
              })}

              <Pagination
                onChange={this.muda}
                current={this.state.current}
                total={this.state.total}
              />
            </Grid>
          </main>
        </div>
      </Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex",
    flexGrow: 1,
    minHeight: "100vh"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  grow: {
    flexGrow: 1
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    marginRight: 20,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200
      }
    }
  }
});

export default withStyles(styles)(Alunos);
