import React, {Component,Fragment} from "react"
import Paper from "@material-ui/core/Paper"
import {Bar, TitleBar} from "./styles"

class FormPaper extends Component {
	render(){
		return (
			<Fragment>
				<Bar>
					<TitleBar>{this.props.title}</TitleBar>
				</Bar>
				<Paper style={{padding: 20}}>
					{this.props.children}
				</Paper>
			</Fragment>
		)
	}
}

export default FormPaper