import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

// Material-UI
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddBox from "@material-ui/icons/AddBox";

// Custom Components
import List from "../../components/List";
import Menu from "../../components/Menu";
import api from "../../services/api";

import { CircularProgress } from "@material-ui/core";

class Turmas extends Component {
  state = {
    turma: "",
    turmas: [],
    isLoading: true,
  };

  componentDidMount() {
    this.getTurmas();
  }

  async getTurmas() {
    try {
      const turmas = await api.get(`/classrooms`);
      this.setState({
        ...this.state,
        turmas: [...turmas.data],
        isLoading: false,
      });
    } catch (err) {
      console.warn(err);
    }
  }

  removeTurma() {}

  mostraTurno = (turno) => {
    switch (turno) {
      case "1":
        return "Matutino";
      case "2":
        return "Vespertino";
      case "3":
        return "Noturno";
      case "4":
        return "Integral";
      default:
        break;
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <div className={classes.root}>
          <Menu />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <AppBar position="static" style={{ marginBottom: 20 }}>
              <Toolbar>
                <Typography
                  variant="h5"
                  color="inherit"
                  className={classes.grow}
                >
                  Turmas
                </Typography>
                <Tooltip title="Adicionar Turma" aria-label="Add">
                  <Link
                    to="/adicionar-turma"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <IconButton
                      className={classes.menuButton}
                      color="inherit"
                      aria-label="Menu"
                    >
                      <AddBox />
                    </IconButton>
                  </Link>
                </Tooltip>
              </Toolbar>
            </AppBar>

            {this.state.isLoading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
            {!this.state.isLoading && this.state.turmas.length === 0
              ? "Nenhuma turma encontrada"
              : this.state.turmas.map((turma, index) => {
                  return (
                    <List
                      key={turma.id}
                      lista={[
                        { key: "Nome", value: turma.name },
                        { key: "Curso", value: turma.course },
                        { key: "Faculdade", value: turma.college },
                        { key: "Turno", value: turma.shift },
                      ]}
                      view={`/turma/${turma.id}`}
                      edit={`/editar-turma/${turma.id}`}
                      // delete={this.removeTurma.bind(this, index)}
                    />
                  );
                })}
          </main>
        </div>
      </Fragment>
    );
  }
}

const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    minHeight: "100vh",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  grow: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto",
  },
});

export default withStyles(styles, { withTheme: true })(Turmas);
