import React from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { uniqueId } from "lodash";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import { ListContainer, Info, ButtonContainer } from "./styles";

const List = (props) => {
  return (
    <ListContainer>
      <Info>
        {props.lista.map((item) => {
          return (
            <div key={uniqueId()}>
              <Typography variant="caption">{item.key}</Typography>
              <Typography variant="body1">{item.value}</Typography>
            </div>
          );
        })}

        <ButtonContainer>
          <a href={props.view} rel="noopener noreferrer" target="_blank">
            <Fab color="primary" style={{ fontSize: 5, width: 37, height: 20 }}>
              <Visibility size="small" />
            </Fab>
          </a>

          <Fab
            onClick={props.onPayLocal.bind(this)}
            color="primary"
            style={{ fontSize: 5, width: 37, height: 20, marginLeft: 5 }}
          >
            <Edit size="small" />
          </Fab>
          <Fab
            onClick={props.delete.bind(this)}
            color="secondary"
            style={{ fontSize: 5, width: 37, height: 20, marginLeft: 5 }}
          >
            <Delete size="small" />
          </Fab>
        </ButtonContainer>
      </Info>
    </ListContainer>
  );
};

export default List;
